import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AppleIconHelpGif from '../../assests/gif/icon-apple-help.gif'
import { IconButton } from '@mui/material'

function ZohoChat() {
  const { i18n } = useTranslation()
  const [firstLoad, setFirstLoad] = useState(false)
  const [zohoChatOpen, setZohoChatOpen] = useState(false)

  let zohoButton = null

  const handleSetZohoButtonEventListener = () => {
    if (!zohoButton) {
      const target = document.getElementById('zsiq_float')
      target.addEventListener('click', () => {
        if (target.style.opacity === '0') {
          target.style.opacity = '1'
          setZohoChatOpen(true)
        } else {
          target.style.opacity = '0'
          setZohoChatOpen(false)
        }
      })
      zohoButton = target
    }
  }

  const loadScripts = () => {
    const existingInlineScript = document.querySelector('#zsiq-inline-script')
    const existingExternalScript = document.querySelector('#zsiqscript')
    const existingWidgetElement = document.querySelector('[data-id="zsalesiq"]')

    // Remove existing scripts and widget
    if (existingInlineScript) document.head.removeChild(existingInlineScript)
    if (existingExternalScript) document.head.removeChild(existingExternalScript)
    if (existingWidgetElement) existingWidgetElement.remove()

    // Create and add the inline script
    const inlineScript = document.createElement('script')
    inlineScript.id = 'zsiq-inline-script'
    inlineScript.type = 'text/javascript'
    inlineScript.innerHTML = `
    window.$zoho = window.$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
      ready: function() {
        console.log('Zoho widget ready');
      }
    };
  `
    document.head.appendChild(inlineScript)

    // Create and add the external script
    const externalScript = document.createElement('script')
    externalScript.id = 'zsiqscript'
    externalScript.src =
      'https://salesiq.zohopublic.com/widget?wc=siq0f68ffff6ecc70675f74229423276aec4d4f7d47e6d0ca388aa1b0d87b7c659f'
    externalScript.defer = true

    // Reinitialize the widget when the external script loads
    externalScript.onload = () => {
      if (window.$zoho && window.$zoho.salesiq) {
        console.log('Reinitializing Zoho widget')
        window.$zoho.salesiq.ready()
      }
    }

    document.head.appendChild(externalScript)
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const iframe = document.querySelector('iframe')
      if (iframe && iframe.contentDocument) {
        const iframeDoc = iframe.contentDocument

        // Apply your styles
        const style = iframeDoc.createElement('style')
        style.innerHTML = `
    .footer-tab[data-zsqa=article] {
      display: none !important;
    }
  `
        iframeDoc.head.appendChild(style)
        handleSetZohoButtonEventListener()
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })
    loadScripts()
  }, [])

  // const loadScripts = () => {
  //   const existingInlineScript = document.querySelector('#zsiq-inline-script')
  //   const existingExternalScript = document.querySelector('#zsiqscript')
  //   if (existingInlineScript) document.head.removeChild(existingInlineScript)
  //   if (existingExternalScript) document.head.removeChild(existingExternalScript)
  //   const inlineScript = document.createElement('script')
  //   inlineScript.id = 'zsiq-inline-script'
  //   inlineScript.type = 'text/javascript'
  //   inlineScript.innerHTML = `
  //       window.$zoho = window.$zoho || {};
  //       $zoho.salesiq = $zoho.salesiq || { ready: function() {
  //        } };
  //     `
  //   document.head.appendChild(inlineScript)
  //
  //   const externalScript = document.createElement('script')
  //   externalScript.id = 'zsiqscript'
  //   externalScript.src =
  //     'https://salesiq.zohopublic.com/widget?wc=siq0f68ffff6ecc70675f74229423276aec4d4f7d47e6d0ca388aa1b0d87b7c659f'
  //   externalScript.defer = true
  //   document.head.appendChild(externalScript)
  // }

  // useEffect(() => {
  //   document.documentElement.lang = i18n.language
  //   document.querySelector('[data-id="zsalesiq"]')?.remove()
  //   zohoButton = null
  //   loadScripts()
  // }, [i18n.language])

  useEffect(() => {
    document.documentElement.lang = i18n.language
  }, [i18n.language])

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        bottom: '30px',
        right: '30px',
      }}
    >
      <IconButton
        sx={{
          width: '79px',
          height: '79px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
          opacity: zohoChatOpen ? 0 : 1,
        }}
      >
        <img src={AppleIconHelpGif} alt="GIF" style={{ width: '115px', height: '115px' }} />
      </IconButton>
    </Box>
  )
}

export default ZohoChat
