import React, { useEffect, useState } from 'react'
import { privateRoutes, publicRoutes, adminRoutes, operatorRoutes, expertRoutes } from './Routes'
import { Route, Routes, useLocation } from 'react-router-dom'
import Footer2 from '../components/Footer2'
import { Box, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import Sidebar from '../components/Sidebar'
import Header from '../components/Layout/Header/Header'
import PageTitle from '../components/PageTitle'
import HelpSidebar from '../pages/Help/HelpSidebar'
import HelpHeader from '../pages/Help/HelpHeader'
import Layout from '../components/Layout'
import { LOCAL_ROLE } from '../utils/constants/global.constants'
import ZohoChat from '../components/HelpWidget/HelpWidget'
import { useTranslation } from 'react-i18next'

const AppRouter = () => {
  const { i18n } = useTranslation()
  const auth = useSelector((state) => state.auth.isAuthenticated)
  const role = useSelector((state) => state.auth.role)

  const largeOnly = useMediaQuery('(min-width: 1350px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const location = useLocation()

  const [hideZoho, setHideZoho] = useState(false)

  const isCustomLayoutRoute = () => {
    if (location.pathname === '/go-greener') return true
    return false
  }

  return (
    <Box>
      {auth && role === 'ADMIN' ? (
        <Layout>
          <Routes>
            {adminRoutes.map((route, index) => {
              return <Route element={route.component} path={route.path} key={index} />
            })}
          </Routes>
        </Layout>
      ) : auth && !isCustomLayoutRoute() ? (
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
            }}
          >
            {!(location.pathname === '/help') ? <Sidebar /> : <HelpSidebar />}
            <Box
              sx={{
                flex: 1,
                pt: {
                  xs: 0,
                  lg: '72px',
                },
              }}
            >
              {!(location.pathname === '/help') ? <Header /> : <HelpHeader />}
              <Box
                className={tabletOnly ? 'tablet-only' : ''}
                sx={{
                  paddingTop: {
                    xs: 0,
                    lg: '30px',
                  },
                  paddingLeft: {
                    xs: 0,
                    lg: '23px',
                  },
                  paddingRight: {
                    xs: 0,
                    lg: largeOnly ? '98px' : '23px',
                  },
                  paddingBottom: {
                    xs: 0,
                    lg: '33px',
                  },
                  display: {
                    lg: 'block',
                  },
                  '&.tablet-only': {
                    width: '100vw',
                  },
                }}
              >
                {role === LOCAL_ROLE.COMPANY && !hideZoho && <ZohoChat />}
                {!(location.pathname === '/help') && <PageTitle />}
                <Routes>
                  {privateRoutes.map((route, index) => {
                    if (route.path.includes('auctions') && role === 'OPERATOR') {
                      return null
                    }

                    if (route.path.includes('auctions') && role === 'EXPERT') {
                      return null
                    }

                    if (role && role === 'OPERATOR' && route.operatorPath) {
                      return (
                        <Route
                          element={route.component}
                          path={route.operatorPath}
                          key={index}
                          index={!!route.index}
                        />
                      )
                    }

                    if (role && role === 'OPERATOR' && route.operatorComponent) {
                      return (
                        <Route
                          element={route.operatorComponent}
                          path={route.path}
                          key={index}
                          index={!!route.index}
                        />
                      )
                    }

                    if (role && role === 'EXPERT' && route.expertPath) {
                      return (
                        <Route
                          element={route.component}
                          path={route.expertPath}
                          key={index}
                          index={!!route.index}
                        />
                      )
                    }

                    if (role && role === 'EXPERT' && route.expertComponent) {
                      return (
                        <Route
                          element={route.expertComponent}
                          path={route.path}
                          key={index}
                          index={!!route.index}
                        />
                      )
                    }

                    return (
                      <Route
                        element={route.component}
                        path={route.path}
                        key={index}
                        index={!!route.index}
                      />
                    )
                  })}
                  {role === 'OPERATOR' ? (
                    <>
                      {operatorRoutes.map((route, index) => {
                        return (
                          <Route
                            element={route.component}
                            path={route.path}
                            key={index}
                            index={!!route.index}
                          />
                        )
                      })}
                    </>
                  ) : null}

                  {role === 'EXPERT' ? (
                    <>
                      {expertRoutes.map((route, index) => {
                        return (
                          <Route
                            element={route.component}
                            path={route.path}
                            key={index}
                            index={!!route.index}
                          />
                        )
                      })}
                    </>
                  ) : null}
                </Routes>
              </Box>
            </Box>
          </Box>
          <Footer2 />
        </React.Fragment>
      ) : (
        <Box>
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route element={route.component} path={route.path} key={index} />
            ))}
          </Routes>
        </Box>
      )}
    </Box>
  )
}

export default AppRouter
