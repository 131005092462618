import * as Yup from 'yup'
import { formErrors } from '../../components/Forms/FormTemplates/formErrors'
import { countries } from '../../default-data/coutries'

export const signUpFormInitialState = {
  login: '',
  firstName: '',
  surname: '',
  email: '',
  phoneNumber: '+41',
  companyName: '',
  country: countries.find((item) => item.code === 'CH'),
  addressLine1: '',
  addressLine2: '',
  postcode: '',
  city: '',
  landlineNumber: '+41',
  tradeLicenseNumber: '',
  taxRegistrationNumber: '',
  tradeLicense: null,
  consent: false,
}

export const signUpFormValidationSchema = Yup.object().shape({
  login: Yup.string().required(formErrors.notEmpty),
  firstName: Yup.string().required(formErrors.notEmpty),
  surname: Yup.string().required(formErrors.notEmpty),
  email: Yup.string().required(formErrors.notEmpty).email(formErrors.invalidEmail),
  phoneNumber: Yup.string().required(formErrors.notEmpty),
  companyName: Yup.string().required(formErrors.notEmpty),
  country: Yup.mixed().required(formErrors.notEmpty),
  addressLine1: Yup.string().required(formErrors.notEmpty),
  postcode: Yup.string().required(formErrors.notEmpty),
  city: Yup.string().required(formErrors.notEmpty),
  landlineNumber: Yup.string().required(formErrors.notEmpty),
  tradeLicenseNumber: Yup.string().required(formErrors.notEmpty),
  taxRegistrationNumber: Yup.string().required(formErrors.notEmpty),
  tradeLicense: Yup.mixed().required(formErrors.notEmpty),
  consent: Yup.boolean().oneOf([true], formErrors.privacyPolicyConsent),
})
