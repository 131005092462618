import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const ButtonSuccess = ({
  children,
  sx,
  isLoading,
  loadingText = 'preloader_loading',
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Button
      {...props}
      sx={{
        height: '48px',
        borderRadius: '4px',
        paddingLeft: '50px',
        paddingRight: '50px',
        background: colorsConfig.mainGreen,
        textTransform: 'none',
        color: colorsConfig.mainBlack,
        fontWeight: 700,
        fontSize: '16px',
        transition: '0.25s all ease-in-out',
        display: 'flex',
        gap: '12px',
        '&:hover': {
          background: colorsConfig.mainGreen,
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          background: colorsConfig.buttonDisabled,
          ...(isLoading && {
            background: colorsConfig.mainGreen,
            color: colorsConfig.mainBlack,
            opacity: 0.7,
          }),
        },
        ...(isLoading && {
          background: colorsConfig.mainGreen,
          color: colorsConfig.mainBlack,
          opacity: 0.7,
        }),
        ...sx,
      }}
    >
      {isLoading ? t(loadingText) : children}
      {isLoading && (
        <CircularProgress size={25} thickness={4} sx={{ color: colorsConfig.mainBlack }} />
      )}
    </Button>
  )
}

export default ButtonSuccess
