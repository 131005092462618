import React, { useEffect, useRef, useState } from 'react'
import ModalWrap from '../../../../../components/Modals/ModalWrap'
import UITextHeadlineLarge from '../../../../../ui/UIText/UITextHeadlineLarge'
import { Autocomplete, Box, IconButton, Stack } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import UITextRegular from '../../../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../../../config/themeConfig'
import IconDragAndDrop from '../../../../../assests/img/icons/icon-drag-and-drop.svg'
import { useDispatch, useSelector } from 'react-redux'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'
import {
  attachNewPolicies,
  getImportedPolicies,
  getPolicyImport,
  getUnlinkedPolicies,
  importPolicies,
} from '../../../../../store/features/rviOperator/rviOperator.actions'
import UseImportRvi from '../../../../../hooks/UseImportRvi'
import { getAllCompanies } from '../../../../../store/features/companiesSlice/companies.actions'
import { setImportedPolicyMatchedCompany } from '../../../../../store/features/rviOperator/rviOperator.reducer'
import TextFieldSmallEdible from '../../../../../ui/TextFieldSmallEdible'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import { toast } from 'react-toastify'
import { CloseRounded } from '@mui/icons-material'

const UploadRVIPoliciesModal = ({ open, setOpen, onImportSuccess }) => {
  const { setPolicyImport, importResult, importResultError } = UseImportRvi({ onImportSuccess })

  const isLoading = useSelector((state) => state.rviOperator.importPolicies.isLoading)
  const importFile = useSelector((state) => state.rviOperator.importPolicies.importResult)
  const isLoadingCompanies = useSelector((state) => state.companies.allCompanies.isLoading)
  const companies = useSelector((state) => state.companies.allCompanies.data)
  const finalPolicyImport = useSelector((state) => state.rviOperator.importPolicies.imported.data)

  const dispatch = useDispatch()

  const [selectedCompany, setSelectedCompany] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [isParsing, setIsParsing] = useState(false)
  const [linkingToDealers, setLinkingToDealers] = useState(false)

  const timerRef = useRef(null)

  const handleImportPolicies = async () => {
    const associatedCompany = selectedCompany?.value?.['@id']
    const res = await dispatch(importPolicies({ data: fileToUpload, associatedCompany }))
    if (importPolicies.fulfilled.match(res)) {
      setIsParsing(true)
    }
  }

  const handleSetFileToUpload = (file) => {
    setFileToUpload(file)
  }

  const handleAttachPolices = async () => {
    setIsParsing(false)
    setLinkingToDealers(true)
    await dispatch(attachNewPolicies({ policyImportId: importFile?.id }))
    setLinkingToDealers(false)
    setOpen(false)
    dispatch(getUnlinkedPolicies())
  }

  const handleImportResult = async (policyImportId) => {
    const res = await dispatch(getPolicyImport({ policyImportId }))
    if (getPolicyImport.fulfilled.match(res)) {
      setIsParsing(false)
      toast.success(
        <UITextRegular>
          Upload successful. <br />
          Uploaded: {res?.payload?.uploadedPolicies}. Duplicated: {res?.payload?.duplicatePolicies}
        </UITextRegular>,
      )
      onImportSuccess()
      setOpen(false)
    }

    // const res = await dispatch(getImportedPolicies({ policyImportId }))
    // if (getImportedPolicies.fulfilled.match(res)) {
    //   if (timerRef.current) clearTimeout(timerRef.current)
    //   setOpen(false)
    //   onImportSuccess()
    // }
  }

  useEffect(() => {
    if (importResult) {
      handleImportResult(importResult?.policyImportId)
    }
  }, [importResult])

  useEffect(() => {
    if (importResultError) {
      handleImportResult()
    }
  }, [importResultError])

  useEffect(() => {
    if (importFile) {
      setPolicyImport(importFile?.id)
      // timerRef.current = setTimeout(() => {
      //   console.log('timeout triggered')
      //   handleImportResult(importFile?.id)
      // }, 10000)
    }
  }, [importFile])

  useEffect(() => {
    dispatch(getAllCompanies())
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [])

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      {isLoading || isParsing || isLoadingCompanies || linkingToDealers ? (
        <UIPreloader
          text={
            isLoading
              ? 'Uploading...'
              : isParsing
                ? 'Parsing the file...'
                : isLoadingCompanies
                  ? 'Loading...'
                  : linkingToDealers
                    ? 'Linking to dealers...'
                    : ''
          }
        />
      ) : null}
      <Stack gap="30px">
        <UITextHeadlineLarge
          sx={{
            fontSize: {
              xs: '24px',
              md: '32px',
            },
          }}
          text="Upload RVI policies"
        />
        <Autocomplete
          fullWidth
          options={companies.map((item) => ({
            value: item,
            label: item?.name,
          }))}
          value={selectedCompany}
          onChange={(e, newVal) => {
            setSelectedCompany(newVal)
          }}
          renderInput={(params) => (
            <TextFieldSmallEdible {...params} isAutocomplete label="Select dealer" required />
          )}
        />
        {fileToUpload && (
          <Box
            sx={{
              pl: '15px',
              pr: '5px',
              py: '3px',
              borderRadius: '6px',
              border: '1px solid',
              borderColor: colorsConfig.mainBlue,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <UITextRegular text={fileToUpload?.name} />
            <IconButton onClick={() => setFileToUpload(null)}>
              <CloseRounded sx={{ fill: colorsConfig.darkRed }} />
            </IconButton>
          </Box>
        )}
        <FileUploader
          name="file"
          types={['xlsx']}
          hoverTitle="Drop here"
          label="Drag & Drop policies file here"
          classes="upload-picture"
          maxSize={10}
          onDrop={handleSetFileToUpload}
          onSelect={handleSetFileToUpload}
          children={
            <Box
              className={selectedCompany ? '' : 'disabled'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                height: '150px',
                borderRadius: '8px',
                backgroundColor: '#F7FAFB',
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
                '&.disabled': {
                  opacity: 0.5,
                  pointerEvents: 'none',
                },
              }}
            >
              <img src={IconDragAndDrop} alt="" />
              <UITextRegular
                sx={{ color: colorsConfig.iconGray }}
                text="Drag and drop files here"
              />
            </Box>
          }
          sx={{
            width: '100%',
            position: 'relative',
            'label.is-disabled': {
              border: 'none',
              backgroundColor: '#F7FAFB',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
              borderRadius: '6px',
            },
            '& .MuiTypography-root': {
              width: '100px',
              marginTop: '-3px',
            },
          }}
        />
        <Stack direction="row" gap="8px">
          <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonLG
            disabled={!selectedCompany || !fileToUpload}
            sx={{ flex: 1 }}
            onClick={() => handleImportPolicies()}
          >
            Upload policies
          </ButtonLG>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default UploadRVIPoliciesModal
