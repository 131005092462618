import {
  cleanObject,
  getFloatStringValueOrNull,
  getIntValueOrNull,
} from '../../../../utils/helpers/common.helpers'

export const editRVIPolicyInitialState = {
  policyNumber: '',
  internalCustomerId: '',
  dealerId: '',
  modelId: '',
  customerContractNumber: '',
  color: '',
  mileage: '',
  vehicleGrossPriceExcludingOptionsExcludingVAT: '',
  vehicleOptionsExcludingVAT: '',
  discountExcludingVAT: '',
  vehicleGrossPriceIncludingOptionsExcludingVAT: '',
  vehicleNetPriceExcludingVAT: '',
  mileagePa: '',
  contractDurationInMonths: '',
  offerId: '',
  firstRegistrationDate: '',
  salesPeriod: '',
  businessPartnerId: '',
  RVWarrant: '',
  customerReference: '',
  listPriceIncludingOptionsExcludingVAT: '',
  RVPremiumExcludingVAT: '',
  RVPremiumPercent: '',
  risk: '',
  handlingFee: '',
  inRisk: '',
  reinsurance: '',
  reinsurer: '',
  reinsuranceBrutto: '',
  insuranceTax: '',
  reinsuranceNetto: '',
  reinsuranceRisk: '',
  reinsuranceVK: '',
  reserves: '',
  status: '',
  realEndDate: null,
  initialRVPercent: '',
  initialResidualValueIncludingVAT: '',
  RVPremiumPaid: false,
  RVIStartDate: null,
  RVIEndDate: null,
  modelName: '',
  makeName: '',
  deductions: '',
  deductionReason: '',
  baseResidualValuePaidOutIncludingVAT: '',
  resaleValueSoldIncludingVAT: '',
  resaleDate: null,
  resaleParty: '',
  resultIncludingVAT: '',
  dealerInvoiceResale: '',
}

export const getRVIPolicyFormData = (data) => {
  const {
    make,
    model,
    mileage,
    vehicleGrossPriceExcludingOptionsExcludingVAT,
    vehicleOptionsExcludingVAT,
    discountExcludingVAT,
    vehicleGrossPriceIncludingOptionsExcludingVAT,
    vehicleNetPriceExcludingVAT,
    mileagePa,
    contractDurationInMonths,
    listPriceIncludingOptionsExcludingVAT,
    RVPremiumExcludingVAT,
    RVPremiumPercent,
    risk,
    handlingFee,
    reinsuranceBrutto,
    insuranceTax,
    reinsuranceNetto,
    reinsuranceRisk,
    reinsuranceVK,
    reserves,
    initialRVPercent,
    initialResidualValueIncludingVAT,
    deductions,
    baseResidualValuePaidOutIncludingVAT,
    resaleValueSoldIncludingVAT,
    resultIncludingVAT,
  } = data ?? {}
  const details = cleanObject({
    ...data,
    make: make?.['@id'] ?? null,
    model: model?.['@id'] ?? null,
    risk: getFloatStringValueOrNull(risk),
    mileage: getIntValueOrNull(mileage),
    vehicleGrossPriceExcludingOptionsExcludingVAT: getFloatStringValueOrNull(
      vehicleGrossPriceExcludingOptionsExcludingVAT,
    ),
    vehicleOptionsExcludingVAT: getFloatStringValueOrNull(vehicleOptionsExcludingVAT),
    discountExcludingVAT: getFloatStringValueOrNull(discountExcludingVAT),
    vehicleGrossPriceIncludingOptionsExcludingVAT: getFloatStringValueOrNull(
      vehicleGrossPriceIncludingOptionsExcludingVAT,
    ),
    vehicleNetPriceExcludingVAT: getFloatStringValueOrNull(vehicleNetPriceExcludingVAT),
    mileagePa: getIntValueOrNull(mileagePa),
    contractDurationInMonths: getIntValueOrNull(contractDurationInMonths),
    listPriceIncludingOptionsExcludingVAT: getFloatStringValueOrNull(
      listPriceIncludingOptionsExcludingVAT,
    ),
    RVPremiumExcludingVAT: getFloatStringValueOrNull(RVPremiumExcludingVAT),
    RVPremiumPercent: getIntValueOrNull(RVPremiumPercent),
    handlingFee: getFloatStringValueOrNull(handlingFee),
    reinsuranceBrutto: getFloatStringValueOrNull(reinsuranceBrutto),
    insuranceTax: getFloatStringValueOrNull(insuranceTax),
    reinsuranceNetto: getFloatStringValueOrNull(reinsuranceNetto),
    reinsuranceRisk: getFloatStringValueOrNull(reinsuranceRisk),
    reinsuranceVK: getFloatStringValueOrNull(reinsuranceVK),
    reserves: getFloatStringValueOrNull(reserves),
    initialRVPercent: getIntValueOrNull(initialRVPercent),
    initialResidualValueIncludingVAT: getFloatStringValueOrNull(initialResidualValueIncludingVAT),
    deductions: getFloatStringValueOrNull(deductions),
    baseResidualValuePaidOutIncludingVAT: getFloatStringValueOrNull(
      baseResidualValuePaidOutIncludingVAT,
    ),
    resaleValueSoldIncludingVAT: getFloatStringValueOrNull(resaleValueSoldIncludingVAT),
    resultIncludingVAT: getFloatStringValueOrNull(resultIncludingVAT),
  })
  // Temp: removing old fields ---START
  delete details?.vehicleGrossPriceExcludingOptionsIncludingVAT
  delete details?.vehicleOptionsIncludingVAT
  delete details?.discountIncludingVAT
  delete details?.vehicleGrossPriceIncludingOptionsIncludingVAT
  delete details?.vehicleNetPriceIncludingVAT
  // Temp: removing old fields ---END
  return {
    details,
  }
}
