export const getCompanyAddressesListData = (companyAddresses, addressTypes, t) => {
  return companyAddresses.map((companyAddress) => {
    const { addressLine1, city, postcode, country, state, comment, type } =
      companyAddress?.address ?? {}
    const mainText = [postcode, addressLine1, city, t(state), country].filter((e) => !!e).join(', ')
    return {
      ...companyAddress,
      displayData: {
        title: type ? addressTypes?.find((e) => e?.['@id'] === type)?.name : 'All',
        mainText,
        bottomText: comment,
      },
    }
  })
}
