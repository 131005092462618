import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, CircularProgress, Grid, InputAdornment, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getRviPolicy,
  updateRviPolicy,
} from '../../../../store/features/rviPolicy/rviPolicy.actions'
import { resetRviPolicy } from '../../../../store/features/rviPolicy/rviPolicy.reducer'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import UIBreadcrumbs from '../../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { AssuredWorkloadRounded } from '@mui/icons-material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'
import ButtonSuccess from '../../../../ui/Buttons/ButtonSuccess'
import { editRVIPolicyInitialState, getRVIPolicyFormData } from './EditRVIPolicy.helpers'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import TextFieldSmall from '../../../../ui/TextFieldSmall'
import { formErrors } from '../../../../components/Forms/FormTemplates/formErrors'
import { colorsConfig } from '../../../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const EditRVIPolicy = () => {
  const { t } = useTranslation()
  const isLoading = useSelector((state) => state.rviPolicy.isLoading)
  const isSaving = useSelector((state) => state.rviPolicy.updatePolicy.isLoading)
  const policy = useSelector((state) => state.rviPolicy.data)

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [formData, setFormData] = useState(editRVIPolicyInitialState)

  // TODO: Refactor makes and models

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [makesLoading, setMakesLoading] = useState(false)
  const [firstBlur, setFirstBlur] = useState(false)

  const handleSetMake = (value) => {
    setFormData({
      ...formData,
      make: {
        ...formData?.make,
        name: value,
      },
    })
  }

  const handleSetModel = (value) => {
    let matchModel = null
    if (value) {
      matchModel = models.find((item) => value === item?.name)
    }
    setFormData({
      ...formData,
      model: {
        ...formData?.model,
        name: value,
        '@id': matchModel ? matchModel['@id'] : '',
      },
    })
  }

  const getMakes = (searchLine) => {
    privateRequest.get(`${API_ENDPOINTS.makes}?name=${searchLine}`).then((response) => {
      setMakes(response.data['hydra:member'])
      setMakesLoading(false)
      const matchMake = response.data['hydra:member'].find(
        (item) => item?.name === formData?.make?.name,
      )
      if (matchMake) {
        setFormData({
          ...formData,
          make: {
            ...formData?.make,
            '@id': matchMake['@id'],
          },
        })
        getModels(matchMake['@id'])
      }
    })
  }

  const getModels = (makePath) => {
    privateRequest
      .get(`${API_ENDPOINTS.models}?make=${makePath}&pagination=false`)
      .then((response) => {
        setModels(response.data['hydra:member'])
        if (response.data['hydra:member'].length === 0) {
          toast.error('Can not add this make: no models found')
        }
      })
  }

  useEffect(() => {
    if (firstBlur) {
      if (formData?.make.name) {
        const matchMake = makes.find((item) => {
          return item?.name === formData?.make?.name
        })
        if (matchMake) {
          setMakesLoading(false)
          setFormData({
            ...formData,
            make: {
              ...formData?.make,
              '@id': matchMake['@id'],
            },
          })
          getModels(matchMake['@id'])
        } else if (formData?.make?.name?.length >= 2) {
          setFormData({
            ...formData,
            make: {
              ...formData?.make,
              '@id': null,
            },
          })

          setModels([])
          setMakesLoading(true)
          getMakes(formData?.make?.name)
        }
      } else {
        setFormData({
          ...formData,
          make: {
            ...formData?.make,
            '@id': null,
          },
        })
        setModels([])
      }
    }
    setFirstBlur(true)
  }, [formData?.make?.name])

  // TODO: Refactor makes and models

  const handleSubmit = async () => {
    const res = await dispatch(
      updateRviPolicy({
        policyId: params.id,
        data: getRVIPolicyFormData(formData),
      }),
    )
    if (updateRviPolicy.fulfilled.match(res)) {
      toast.success('Policy has been updated')
      navigate('/operator/rvi')
    }
  }

  useEffect(() => {
    if (policy) {
      setFormData({
        ...formData,
        ...policy.details,
        makeName: policy?.details?.make?.name ?? policy?.details?.makeName ?? '',
        modelName: policy?.details?.model?.name ?? policy?.details?.modelName ?? '',
      })
    }
  }, [policy])

  useEffect(() => {
    dispatch(getRviPolicy({ policyId: params.id }))
    return () => {
      dispatch(resetRviPolicy())
    }
  }, [])

  return (
    <Box sx={{ position: 'relative', minHeight: '300px' }}>
      {isLoading ? (
        <UIPreloader text="Loading the policy..." />
      ) : (
        <Stack gap="30px">
          <Stack gap="30px" sx={{ position: 'relative' }}>
            {isSaving && <UIPreloader size={60} />}
            <Stack gap="30px">
              <UIBreadcrumbs
                pages={[
                  {
                    icon: <AssuredWorkloadRounded />,
                    name: 'RVI Policies',
                    link: '/operator/rviOperator',
                  },
                  {
                    name: policy?.details?.policyNumber
                      ? `Edit ${policy?.details?.policyNumber}`
                      : '-',
                  },
                ]}
              />
            </Stack>
            <Grid container spacing="30px">
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Policy Number"
                  value={formData?.policyNumber}
                  disabled
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Internal customer ID"
                  value={formData.internalCustomerId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      internalCustomerId: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Dealer ID"
                  value={formData?.dealerId}
                  disabled
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Dealer name (from the file)"
                  value={formData?.dealerName}
                  disabled
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Model ID"
                  value={formData?.modelId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      modelId: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Customer contract number"
                  value={formData.customerContractNumber}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      customerContractNumber: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  required
                  fullWidth
                  id="car-make"
                  options={makes.map((item) => {
                    return item.name
                  })}
                  value={formData?.make?.name}
                  loading={!formData?.make?.name ?? makesLoading}
                  loadingText={
                    !formData?.make?.name ? t('common_start_typing') : t('preloader_loading_makes')
                  }
                  onChange={(e, value) => {
                    handleSetMake(value)
                  }}
                  freeSolo
                  renderInput={(params) => (
                    <TextFieldSmall
                      {...params}
                      label={t('form_input_label_make')}
                      size={'small'}
                      value={formData?.make?.name}
                      fullWidth
                      onChange={(e) => {
                        handleSetMake(e.target.value)
                      }}
                      // error={showErrors && !make.id}
                      // helperText={showErrors && !make.id ? formErrors.selectMake : ''}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {makesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ position: 'relative' }}>
                  {!formData?.model && formData?.modelName && (
                    <UITextBodySmall
                      sx={{
                        position: 'absolute',
                        top: '-20px',
                        right: '10px',
                        fontSize: '12px',
                        color: colorsConfig.iconGray,
                      }}
                      text={`Original model name: ${formData?.modelName}`}
                    />
                  )}
                  <Autocomplete
                    required
                    disablePortal
                    id="car-model"
                    options={models.map((item) => {
                      return item.name
                    })}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={props.id}>
                          {option}
                        </li>
                      )
                    }}
                    fullWidth
                    value={formData?.model?.name}
                    loading={models.length === 0}
                    freeSolo
                    loadingText={
                      !firstBlur
                        ? t('common_no_options')
                        : !formData?.make?.['@id']
                          ? t(formErrors.selectMakeFirst)
                          : ''
                    }
                    onChange={(e, newVal) => {
                      handleSetModel(newVal)
                    }}
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        label={t('form_input_label_model')}
                        value={formData?.model?.name}
                        onChange={(e) => {
                          handleSetModel(e.target.value)
                        }}
                        isAutocomplete
                        // error={showErrors && !model.id}
                        // helperText={showErrors && !model.id ? t(formErrors.selectModel) : ''}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Color"
                  value={formData.color}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      color: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible fullWidth label="VIN" value={formData?.vin} disabled />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Mileage"
                  value={formData.mileage}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mileage: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>km</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Vehicle gross price (excluding options, excluding VAT)"
                  value={formData.vehicleGrossPriceExcludingOptionsExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicleGrossPriceExcludingOptionsExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Vehicle options (excluding VAT)"
                  value={formData.vehicleOptionsExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicleOptionsExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Vehicle gross price (including options, excluding VAT)"
                  value={formData.vehicleGrossPriceIncludingOptionsExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicleGrossPriceIncludingOptionsExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Discount (excluding VAT)"
                  value={formData.discountExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      discountExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Vehicle net price (excluding VAT)"
                  value={formData.vehicleNetPriceExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicleNetPriceExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Annual mileage"
                  value={formData.mileagePa}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mileagePa: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>km</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Contract duration"
                  value={formData.contractDurationInMonths}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      contractDurationInMonths: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>months</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Offer ID"
                  value={formData.offerId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      offerId: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="First registration date"
                    inputFormat="DD/MM/YYYY"
                    mask={'__/__/____'}
                    disableMaskedInput={false}
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        fullWidth
                        required
                        value={formData.firstRegistrationDate}
                      />
                    )}
                    value={formData.firstRegistrationDate}
                    onChange={(newVal) => {
                      setFormData({
                        ...formData,
                        firstRegistrationDate: newVal,
                      })
                    }}
                    disableFuture
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="RVI Start Date"
                    inputFormat="DD/MM/YYYY"
                    mask={'__/__/____'}
                    disableMaskedInput={false}
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        fullWidth
                        required
                        value={formData.RVIStartDate}
                      />
                    )}
                    value={formData.RVIStartDate}
                    onChange={(newVal) => {
                      setFormData({
                        ...formData,
                        RVIStartDate: newVal,
                      })
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="RVI End Date"
                    inputFormat="DD/MM/YYYY"
                    mask={'__/__/____'}
                    disableMaskedInput={false}
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        fullWidth
                        required
                        value={formData.RVIEndDate}
                      />
                    )}
                    value={formData.RVIEndDate}
                    onChange={(newVal) => {
                      setFormData({
                        ...formData,
                        RVIEndDate: newVal,
                      })
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Sales period"
                    inputFormat="MM/YYYY"
                    mask={'__/____'}
                    disableMaskedInput={false}
                    renderInput={(params) => (
                      <TextFieldSmallEdible {...params} fullWidth value={formData.salesPeriod} />
                    )}
                    value={formData.salesPeriod}
                    onChange={(newVal) => {
                      setFormData({
                        ...formData,
                        salesPeriod: newVal,
                      })
                    }}
                    views={['year', 'month']}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Business partner ID"
                  value={formData.businessPartnerId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      businessPartnerId: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="RV warrant"
                  value={formData.RVWarrant}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      RVWarrant: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Customer reference"
                  value={formData.customerReference}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      customerReference: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="List price (including options, excluding VAT)"
                  value={formData.listPriceIncludingOptionsExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      listPriceIncludingOptionsExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="RV premium (excluding VAT)"
                  value={formData.RVPremiumExcludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      RVPremiumExcludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="RV premium"
                  value={formData.RVPremiumPercent}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      RVPremiumPercent: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>%</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <UITextRegular text="RVI Premium Paid" />
                <SimpleCheckbox
                  value={formData.RVPremiumPaid}
                  setValue={(value) => {
                    setFormData({
                      ...formData,
                      RVPremiumPaid: value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Risk"
                  value={formData.risk}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      risk: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Insercle handling fee"
                  value={formData.handlingFee}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      handlingFee: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <UITextRegular text="In Risk" />
                <SimpleCheckbox
                  value={formData?.inRisk}
                  setValue={(value) => {
                    setFormData({
                      ...formData,
                      inRisk: value,
                    })
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <UITextRegular text="Reinsurance" />
                <SimpleCheckbox
                  value={formData.reinsurance}
                  setValue={(value) => {
                    setFormData({
                      ...formData,
                      reinsurance: value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Reinsurer"
                  value={formData.reinsurer}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reinsurer: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Reinsurance Brutto"
                  value={formData.reinsuranceBrutto}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reinsuranceBrutto: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Insurance tax"
                  value={formData.insuranceTax}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      insuranceTax: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Reinsurance Netto"
                  value={formData.reinsuranceNetto}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reinsuranceNetto: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="ReInsurance Risk"
                  value={formData.reinsuranceRisk}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reinsuranceRisk: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Reinsurance VK"
                  value={formData.reinsuranceVK}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reinsuranceVK: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Reserves"
                  value={formData.reserves}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reserves: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible fullWidth label="Status" value={formData?.status} disabled />
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Real end date"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        fullWidth
                        disabled
                        error={false}
                        helperText={
                          !formData.realEndDate && 'Set automatically after closing the restitution'
                        }
                      />
                    )}
                    disabled
                    value={formData.realEndDate || null}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Initial Residual Value"
                  value={formData.initialRVPercent}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      initialRVPercent: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>%</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Initial Residual Value (including VAT)"
                  value={formData.initialResidualValueIncludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      initialResidualValueIncludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/*  new */}
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Deductions"
                  value={formData.deductions}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      deductions: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Deduction reason"
                  value={formData.deductionReason}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      deductionReason: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Base Residual Value (Paid out incl. VAT)"
                  value={formData.baseResidualValuePaidOutIncludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      baseResidualValuePaidOutIncludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Resale Value (Sold incl. VAT)"
                  value={formData.resaleValueSoldIncludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      resaleValueSoldIncludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Resale Date"
                    inputFormat="DD/MM/YYYY"
                    mask={'__/__/____'}
                    disableMaskedInput={false}
                    renderInput={(params) => (
                      <TextFieldSmallEdible {...params} fullWidth value={formData.resaleDate} />
                    )}
                    value={formData.resaleDate}
                    onChange={(newVal) => {
                      setFormData({
                        ...formData,
                        resaleDate: newVal,
                      })
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Resale party"
                  value={formData.resaleParty}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      resaleParty: e.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Result incl. VAT"
                  value={formData.resultIncludingVAT}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      resultIncludingVAT: e.target.value,
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UITextBodySmall>CHF</UITextBodySmall>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldSmallEdible
                  fullWidth
                  label="Dealer invoice resale"
                  value={formData.dealerInvoiceResale}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      dealerInvoiceResale: e.target.value,
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" gap="10px" justifyContent="flex-end">
            <ButtonHollow disabled={isSaving} onClick={() => navigate(-1)}>
              Cancel
            </ButtonHollow>
            <ButtonSuccess
              loadingText="preloader_saving_data"
              isLoading={isSaving}
              disabled={isSaving}
              onClick={() => handleSubmit()}
            >
              Save changes
            </ButtonSuccess>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

export default EditRVIPolicy
