import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Popover, Stack, Typography, useMediaQuery } from '@mui/material'
import Logo from '../../assests/img/logo-blue.svg'
import '../../assests/fonts/stylesheet.css'
import './LandingPage.scss'
import iconCarAuction from '../../assests/img/promo/icon-services-1.png'
import iconAppraisal from '../../assests/img/promo/icon-services-2.png'
import iconVirtualGarage from '../../assests/img/promo/icon-services-3.png'
import iconFinancing from '../../assests/img/promo/icon-services-4.png'
import iconInsurance from '../../assests/img/promo/icon-services-5.png'
import iconCarSubscription from '../../assests/img/promo/icon-services-6.png'
import iconWarranty from '../../assests/img/promo/icon-services-7.png'
import iconStockFinancing from '../../assests/img/promo/icon-services-8.png'
import auction1 from '../../assests/img/promo/auction-car-1.png'
import auction2 from '../../assests/img/promo/honda-civic-type-r-resized.png'
import auction3 from '../../assests/img/promo/auction-car-3.png'
import auction4 from '../../assests/img/promo/auction-car-4.png'
import iconGear from '../../assests/img/promo/icon-gear-promo.png'
import iconMileage from '../../assests/img/promo/icon-mileage-promo.png'
import carStep1 from '../../assests/img/promo/car-step-1.svg'
import carStep2 from '../../assests/img/promo/car-step-2.svg'
import carStep3 from '../../assests/img/promo/car-step-3.svg'
import carStep4 from '../../assests/img/promo/car-step-4.svg'
import aboutImage from '../../assests/img/promo/about-img.png'
import getStartedPicture from '../../assests/img/promo/get-started-picture.png'
import IconApple from '../../assests/img/icons/icon-eco-apple.svg'
import ImageVerified from '../../assests/img/fleatify-verified.svg'
import AccordionItem from './AccordionItem'
import SignIn from '../SignIn'
import SignUpCH from '../SignUp/SignUpCH'
import SignUpPromo from './SignUpPromo'
import SignInPromo from './SignInPromo'
import PasswordRecovery from '../PasswordRecovery'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import AuctionTimer from '../CarPage/AuctionCar/AuctionTimer'
import { colorsConfig } from '../../config/themeConfig'
import ModalWrap from '../../components/Modals/ModalWrap'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import LanguageSelect from '../../components/LanguageSelect'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  COMPANY_ADDRESS,
  COMPANY_NAME,
  COMPANY_PHONE,
  MAIN_EMAIL,
  REGION_LOCALE,
} from '../../utils/constants/global.constants'
import UITextLabelMedium from '../../ui/UIText/UITextLabelMedium'
import NoTranslate from '../../NoTranslate'
import UITextBodySmall from '../../ui/UIText/UITextBodySmall'
import FooterCookiesAndPrivacyPolicy from '../../components/LegalDocuments/FooterCookiesAndPrivacyPolicy'

const date1 = new Date()
date1.setDate(date1.getDate() + 2)
const date2 = new Date()
date2.setDate(date2.getDate() + 1)
const date3 = new Date()
date3.setDate(date3.getDate() + 5)

const LandingPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const mobileOnly = useMediaQuery('(max-width: 767px')
  const mobileAndTabletOnly = useMediaQuery('(max-width: 1199px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const smallDesktop = useMediaQuery('(max-width: 1300px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const themeColors = {
    mainBlack: '#3D3D3D',
    mainBlue: '#007DFF',
  }

  const auctionCars = [
    {
      id: 1,
      img: auction1,
      title: 'Ford F-150 Lightning',
      mileage: "26'250 km",
      gear: t('gearbox_automatic'),
      greenCar: true,
      verified: true,
      endTime: date1,
    },
    {
      id: 2,
      img: auction2,
      title: 'Honda Civic Type R',
      mileage: "48'250 km",
      gear: t('gearbox_type_manual'),
      endTime: date3,
    },
    {
      id: 3,
      img: auction3,
      title: 'Porsche Taycan',
      mileage: "38'290 km",
      gear: t('gearbox_type_automatic'),
      greenCar: true,
      endTime: date2,
    },
    {
      id: 4,
      img: auction4,
      title: 'Tesla Model 3',
      mileage: "19'710 km",
      gear: t('gearbox_automatic'),
      endTime: date3,
    },
  ]

  const services = [
    {
      icon: iconVirtualGarage,
      title: t('title_virtual_garage'),
      desc: t('promo_p_virtual_garage'),
    },
    {
      icon: iconAppraisal,
      title: t('title_car_appraisal'),
      desc: t('promo_p_car_appraisal'),
    },
    {
      icon: iconCarAuction,
      title: t('title_car_auction'),
      desc: t('promo_p_car_auction'),
    },
    {
      icon: iconFinancing,
      title: t('title_financing'),
      desc: t('promo_p_financing'),
    },
    {
      icon: iconInsurance,
      title: t('title_insurance'),
      desc: t('promo_p_insurance'),
    },
    {
      icon: iconWarranty,
      title: t('title_car_warranty'),
      desc: t('promo_p_car_warranty'),
    },
    {
      icon: iconCarSubscription,
      title: t('title_car_subscription'),
      desc: t('promo_p_car_subscription'),
    },
    {
      icon: iconStockFinancing,
      title: t('title_stock_financing'),
      desc: t('promo_p_stock_financing'),
    },
  ]

  const [activeImage, setActiveImage] = useState(5)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [getStartedSection, setGetStartedSection] = useState(1)

  const [signInAnchorEl, setSignInAnchorEl] = useState(null)
  const [signInComponent, setSignInComponent] = useState(null)
  const [forgotPasswordComponent, setForgotPasswordComponent] = useState(null)
  const [signInModal, setSignInModal] = useState(false)

  const [currentJokeIndex, setCurrentJokeIndex] = useState(0)
  const [jokeModal, setJokeModal] = useState(false)

  const handlePopoverOpen = (event) => {
    setSignInAnchorEl(event.currentTarget)
    setSignInComponent(true)
  }

  const handlePopoverClose = () => {
    setSignInAnchorEl(null)
    setForgotPasswordComponent(false)
  }

  const handleSignIn = () => {
    setSignInComponent(true)
    setForgotPasswordComponent(false)
  }

  const handleForgotPassword = () => {
    setSignInComponent(false)
    setForgotPasswordComponent(true)
  }

  const handleSignUp = () => {
    handlePopoverClose()
    setTimeout(() => {
      const element = document.getElementById('get-started')
      if (element) {
        handlePopoverClose()
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 0)
  }

  const signInPopoverOpen = Boolean(signInAnchorEl)
  const signInPopoverId = signInPopoverOpen ? 'simple-popover' : undefined

  const faqItems = [
    {
      id: 1,
      title: t('faq_question_register'),
      content: t('faq_answer_register'),
    },
    {
      id: 2,
      title: t('faq_question_sign_in'),
      content: t('faq_answer_sign_in'),
    },
    {
      id: 3,
      title: t('faq_question_quitting'),
      content: t('faq_answer_quitting'),
    },
    {
      id: 4,
      title: t('faq_question_cost'),
      content: t('faq_answer_cost'),
    },
  ]

  const randomJokes = [
    {
      question: t('joke_q1'),
      answer: t('joke_a1'),
    },
    {
      question: t('joke_q2'),
      answer: t('joke_a2'),
    },
    {
      question: t('joke_q3'),
      answer: t('joke_a3'),
    },
    {
      question: t('joke_q4'),
      answer: t('joke_a4'),
    },
    {
      question: t('joke_q5'),
      answer: t('joke_a5'),
    },
  ]

  const handleSetCurrentJoke = () => {
    const jokeCount = JSON.parse(localStorage.getItem('jokeCount'))
    if (!jokeCount || parseInt(jokeCount) === randomJokes.length) {
      localStorage.setItem('jokeCount', '1')
      setCurrentJokeIndex(0)
      return
    }
    setCurrentJokeIndex(parseInt(jokeCount))
    localStorage.setItem('jokeCount', (parseInt(jokeCount) + 1).toString())
  }

  useEffect(() => {
    localStorage.removeItem('X-Appraisal-Token')
    handleSetCurrentJoke()
    let imageSwitcher = setInterval(() => {
      setActiveImage((prev) => {
        if (prev === 6) return 1
        return prev + 1
      })
    }, 3000)

    return () => {
      clearInterval(imageSwitcher)
    }
  }, [])

  return (
    <div className="main-wrapper">
      <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
        <div className="mobile-menu-inner">
          <ul className="header-mobile-menu">
            <li>
              <Button
                href="#services"
                component={'a'}
                sx={{
                  color: '#3D3D3D',
                  transition: '0.25s color ease-in-out',
                  '&:hover': {
                    color: '#007DFF',
                    transition: '0.25s color ease-in-out',
                  },
                }}
                onClick={() => {
                  setMobileMenuOpen(false)
                }}
              >
                {t('promo_section_services')}
              </Button>
            </li>
            <li>
              <Button
                component={'a'}
                href="#steps"
                sx={{
                  color: '#3D3D3D',
                  transition: '0.25s color ease-in-out',
                  '&:hover': {
                    color: '#007DFF',
                    transition: '0.25s color ease-in-out',
                  },
                }}
                onClick={() => {
                  setMobileMenuOpen(false)
                }}
              >
                {t('promo_section_how_it_works')}
              </Button>
            </li>
            <li>
              <Button
                component={'a'}
                href="#faq"
                sx={{
                  color: '#3D3D3D',
                  transition: '0.25s color ease-in-out',
                  '&:hover': {
                    color: '#007DFF',
                    transition: '0.25s color ease-in-out',
                  },
                }}
                onClick={() => {
                  setMobileMenuOpen(false)
                }}
              >
                {t('promo_section_faq')}
              </Button>
            </li>
            <li>
              <Button
                componen={'a'}
                href="#about"
                sx={{
                  color: '#3D3D3D',
                  transition: '0.25s color ease-in-out',
                  '&:hover': {
                    color: '#007DFF',
                    transition: '0.25s color ease-in-out',
                  },
                }}
                onClick={() => {
                  setMobileMenuOpen(false)
                }}
              >
                {t('promo_section_about_us')}
              </Button>
            </li>
          </ul>
          <Button
            variant="contained"
            aria-describedby={signInPopoverId}
            sx={{
              fontFamily: "'Helvetica', sans-serif",
              fontWeight: 700,
              py: '10px',
              px: '60px',
              borderRadius: '4px',
              background: themeColors.mainBlue,
              textTransform: 'none',
              fontSize: '16px',
            }}
            onClick={handlePopoverOpen}
          >
            {t('button_sign_in')}
          </Button>
        </div>
      </div>

      <div className="main-screen-wrapper">
        <header className="header">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <div className="header-logo">
                  <img style={{ width: '132px' }} src={Logo} alt="Fleatify" className="logo-img" />
                </div>
              </div>
              <div className="header-right">
                <nav className="header-nav">
                  <ul className="header-menu">
                    <li>
                      <Button
                        href="#services"
                        component={'a'}
                        sx={{
                          whiteSpace: 'nowrap',
                          color: '#3D3D3D',
                          transition: '0.25s color ease-in-out',
                          '&:hover': {
                            color: '#007DFF',
                            transition: '0.25s color ease-in-out',
                          },
                          textTransform: 'none',
                        }}
                      >
                        {t('promo_section_services')}
                      </Button>
                    </li>
                    <li>
                      <Button
                        href="#steps"
                        component={'a'}
                        sx={{
                          whiteSpace: 'nowrap',
                          color: '#3D3D3D',
                          transition: '0.25s color ease-in-out',
                          '&:hover': {
                            color: '#007DFF',
                            transition: '0.25s color ease-in-out',
                          },
                          textTransform: 'none',
                        }}
                      >
                        {t('promo_section_how_it_works')}
                      </Button>
                    </li>
                    <li>
                      <Button
                        component={'a'}
                        href="#faq"
                        sx={{
                          whiteSpace: 'nowrap',
                          color: '#3D3D3D',
                          transition: '0.25s color ease-in-out',
                          '&:hover': {
                            color: '#007DFF',
                            transition: '0.25s color ease-in-out',
                          },
                          textTransform: 'none',
                        }}
                      >
                        {t('promo_section_faq')}
                      </Button>
                    </li>
                    <li>
                      <Button
                        component={'a'}
                        href="#about"
                        sx={{
                          whiteSpace: 'nowrap',
                          color: '#3D3D3D',
                          transition: '0.25s color ease-in-out',
                          '&:hover': {
                            color: '#007DFF',
                            transition: '0.25s color ease-in-out',
                          },
                          textTransform: 'none',
                        }}
                      >
                        {t('promo_section_about_us')}
                      </Button>
                    </li>
                  </ul>
                </nav>
                <div className="header-actions">
                  <LanguageSelect variant={'standard'} />
                  <Button
                    className={tabletOnly ? 'tablet-only' : ''}
                    variant="contained"
                    aria-describedby={signInPopoverId}
                    sx={{
                      fontFamily: "'Helvetica', sans-serif",
                      fontWeight: 700,
                      py: '10px',
                      px: '56px',
                      borderRadius: '4px',
                      background: themeColors.mainBlue,
                      textTransform: 'none',
                      fontSize: '16px',
                      whiteSpace: 'no-wrap',
                      '&.tablet-only': {
                        px: '40px',
                      },
                    }}
                    onClick={handlePopoverOpen}
                  >
                    {t('button_sign_in')}
                  </Button>
                  <Popover
                    className={xsOnly ? 'xs-only' : ''}
                    id={signInPopoverId}
                    open={signInPopoverOpen}
                    anchorEl={signInAnchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      marginTop: '35px',
                      '& .MuiPaper-root': {
                        borderRadius: '10px',
                      },
                      '&.xs-only': {
                        mt: 0,
                        '& .MuiPopover-paper': {
                          top: '10% !important',
                        },
                      },
                    }}
                  >
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        position: 'relative',
                        width: '693px',
                        height: '418px',
                        padding: '0 65px',
                        '& .MuiContainer-root': {
                          margin: 0,
                        },
                        display: 'flex',
                        alignItems: 'center',
                        '&.xs-only': {
                          width: 'calc(100vw - 32px)',
                          height: 'auto',
                          px: '16px',
                          py: '20px',
                        },
                      }}
                    >
                      {signInComponent ? (
                        <SignIn
                          handleForgotPassword={handleForgotPassword}
                          handleSignUp={handleSignUp}
                        />
                      ) : null}
                      {forgotPasswordComponent ? (
                        <PasswordRecovery handleSignIn={handleSignIn} handleSignUp={handleSignUp} />
                      ) : null}
                    </Box>
                  </Popover>
                </div>
                <div
                  className={`header-burger ${mobileMenuOpen ? 'active' : ''}`}
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  <div className="header-burger-line" />
                  <div className="header-burger-line" />
                  <div className="header-burger-line" />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className={`bg-main-red bg-main ${activeImage === 1 ? 'active' : ''}`} />
        <div className={`bg-main-green bg-main  ${activeImage === 2 ? 'active' : ''}`} />
        <div className={`bg-main-white bg-main  ${activeImage === 3 ? 'active' : ''}`} />
        <div className={`bg-main-black bg-main  ${activeImage === 4 ? 'active' : ''}`} />
        <div
          className={`bg-main-apple bg-main  ${activeImage === 5 || activeImage === 6 ? 'active' : ''}`}
        />
        <div className="main-screen">
          <div className="container">
            <div className="main-screen-inner">
              <Typography
                variant="h2"
                sx={{
                  fontFamily: {
                    sm: "'Inter', sans-serif",
                    xs: "'Helvetica', sans-serif",
                  },
                  fontSize: {
                    sm: '60px',
                    xs: '32px',
                  },
                  lineHeight: {
                    sm: '72px',
                    xs: '36.8px',
                  },
                  fontWeight: 700,
                  color: themeColors.mainBlack,
                  mb: '10px',
                  position: 'relative',
                  zIndex: 2,
                  textAlign: {
                    xs: 'center',
                    sm: 'left',
                  },
                }}
              >
                {activeImage === 5 || activeImage === 6 ? (
                  <Trans
                    components={{
                      br: <br />,
                      span: (
                        <span
                          style={{
                            fontWeight: 700,
                            color: colorsConfig.mainGreen,
                          }}
                        />
                      ),
                    }}
                  >
                    promo_green_title
                  </Trans>
                ) : (
                  <Trans
                    components={{
                      br: <br />,
                    }}
                  >
                    promo_main_title
                  </Trans>
                )}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: '14px',
                    sm: '24px',
                  },
                  fontWeight: 400,
                  fontFamily: "'Helvetica', sans-serif",
                  color: themeColors.mainBlack,
                  mb: {
                    xs: '34px',
                    sm: '42px',
                  },
                  position: 'relative',
                  zIndex: 2,
                  textAlign: {
                    xs: 'center',
                    sm: 'left',
                  },
                  mt: {
                    xs: '26px',
                    sm: 0,
                  },
                }}
              >
                {activeImage === 5 || activeImage === 6
                  ? t('promo_green_desc')
                  : t('promo_main_desc')}
              </Typography>
              <Button
                component={'a'}
                href="#get-started"
                variant="contained"
                sx={{
                  fontFamily: "'Helvetica', sans-serif",
                  fontWeight: 700,
                  py: '10px',
                  px: '60px',
                  borderRadius: '4px',
                  background: themeColors.mainBlue,
                  position: 'relative',
                  zIndex: 2,
                  textTransform: 'none',
                  fontSize: '16px',
                }}
              >
                {t('button_get_started')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <section id="services" className="services">
        <div className="container">
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: '24px',
                sm: '40px',
              },
              fontFamily: 'Helvetica',
              fontWeight: 700,
              textAlign: 'center',
              mb: {
                sm: '30px',
                xs: '16px',
              },
              color: themeColors.mainBlack,
            }}
          >
            {t('promo_section_title_services')}
          </Typography>

          <div className="services-content">
            {services.map((serviceItem, index) => {
              return (
                <div key={index} className="services-item">
                  <div className="services-item-icon">
                    <img src={serviceItem.icon} alt="" />
                  </div>
                  <div className="services-item-right">
                    <div className="services-item-title">{serviceItem.title}</div>
                    <div className="services-item-description">{serviceItem.desc}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="center-content">
            <Button
              variant="contained"
              component={'a'}
              href="#get-started"
              sx={{
                fontFamily: "'Helvetica', sans-serif",
                fontWeight: 700,
                py: '10px',
                px: '60px',
                borderRadius: '4px',
                background: themeColors.mainBlue,
                position: 'relative',
                zIndex: 2,
                textTransform: 'none',
                fontSize: '16px',
              }}
            >
              {t('button_get_started')}
            </Button>
          </div>
        </div>
      </section>

      <section className="eco">
        <div className="container">
          <TextH1
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mb: '6px',
              '&.xs-only': {
                fontSize: '24px',
              },
            }}
          >
            {t('promo_section_title_green')}
          </TextH1>
          <TextNormal sx={{ mb: '40px' }}>{t('promo_section_green_p1')}</TextNormal>
          <div className="eco-wrapper">
            <div className="eco-item">
              <div className="eco-item-count">01</div>
              <div className="eco-item-content">
                <Trans
                  components={{
                    br: <br />,
                  }}
                >
                  promo_section_green_p2
                </Trans>
              </div>
            </div>
            <div className="eco-item">
              <div className="eco-item-count">02</div>
              <div className="eco-item-content">
                <Trans
                  components={{
                    br: <br />,
                  }}
                >
                  promo_section_green_p3
                </Trans>
              </div>
            </div>
            <div className="eco-item">
              <div className="eco-item-count">03</div>
              <div className="eco-item-content">
                <Trans
                  components={{
                    br: <br />,
                  }}
                >
                  promo_section_green_p4
                </Trans>
              </div>
            </div>
          </div>
          {/*<ButtonLG*/}
          {/*  onClick={() => {*/}
          {/*    navigate('/go-greener')*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {t('button_learn_more')}*/}
          {/*</ButtonLG>*/}
        </div>
      </section>
      <section className="auction">
        <div className="container">
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                sm: '40px',
                xs: '24px',
              },
              fontFamily: 'Helvetica',
              fontWeight: 700,
              textAlign: 'center',
              mb: {
                sm: '40px',
                xs: '30px',
              },
              color: themeColors.mainBlack,
            }}
          >
            {t('title_car_auction')}
          </Typography>
          {mobileOnly ? (
            <Slider slidesToShow={1} centerMode={true} centerPadding={'38px'} infinite={false}>
              {auctionCars.map((car, index) => {
                return (
                  <div key={index} className="auction-item">
                    <div className="auction-item-image">
                      <img src={car.img} alt="" />
                    </div>
                    <div className="auction-item-main">
                      <div className="auction-item-title">{car.title}</div>
                      <div className="auction-item-options">
                        <div className="auction-item-option">
                          <div className="auction-item-option-icon">
                            <img src={iconMileage} alt="" />
                          </div>
                          <div className="auction-item-option-value">{car.mileage}</div>
                        </div>
                        <div className="auction-item-option">
                          <div className="auction-item-option-icon">
                            <img src={iconGear} alt="" />
                          </div>
                          <div className="auction-item-option-value">{car.gear}</div>
                        </div>
                      </div>
                      <div className="center-content">
                        <Button
                          variant="contained"
                          sx={{
                            fontFamily: "'Helvetica', sans-serif",
                            fontWeight: 700,
                            fontSize: '16px',
                            py: '5px',
                            px: '13px',
                            borderRadius: '4px',
                            background: themeColors.mainBlue,
                            textTransform: 'none',
                          }}
                          onClick={() => {
                            setSignInModal(true)
                          }}
                        >
                          {t('button_make_a_bid')}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          ) : null}
          {mobileOnly ? null : (
            <Grid container spacing={3} sx={{ mb: '45px' }}>
              {auctionCars.map((car, index) => {
                if (!mobileAndTabletOnly && index > 2) {
                  return null
                }
                return (
                  <Grid key={index} item sm={6} lg={4}>
                    <div className="auction-item">
                      {car.greenCar || car.verified ? (
                        <div className="auction-item-markers">
                          {car.verified ? (
                            <div
                              className={[
                                'auction-item-verified',
                                smallDesktop ? 'small-desktop' : '',
                              ].join(' ')}
                            >
                              <img src={ImageVerified} alt="" />
                            </div>
                          ) : null}
                          {car.greenCar ? (
                            <div className="auction-item-apple">
                              <img src={IconApple} alt="" />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {car.endTime ? (
                        <div className="auction-item-timer">
                          <AuctionTimer
                            endTime={car.endTime}
                            currentStatus={'public'}
                            mode={'mini'}
                          />
                        </div>
                      ) : null}
                      <div className="auction-item-image">
                        <img src={car.img} alt="" />
                      </div>
                      <div className="auction-item-main">
                        <div className="auction-item-title">{car.title}</div>
                        <div className="auction-item-options">
                          <div className="auction-item-option">
                            <div className="auction-item-option-icon">
                              <img src={iconMileage} alt="" />
                            </div>
                            <div className="auction-item-option-value">{car.mileage}</div>
                          </div>
                          <div className="auction-item-option">
                            <div className="auction-item-option-icon">
                              <img src={iconGear} alt="" />
                            </div>
                            <div className="auction-item-option-value">{car.gear}</div>
                          </div>
                        </div>
                        <div className="center-content">
                          <Button
                            variant="contained"
                            sx={{
                              fontFamily: "'Helvetica', sans-serif",
                              fontWeight: 700,
                              fontSize: '16px',
                              py: '5px',
                              px: '13px',
                              borderRadius: '4px',
                              background: themeColors.mainBlue,
                              textTransform: 'none',
                            }}
                            onClick={() => {
                              setSignInModal(true)
                            }}
                          >
                            {t('button_make_a_bid')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          )}

          <div className="center-content">
            <Button
              variant="contained"
              sx={{
                fontFamily: "'Helvetica', sans-serif",
                fontWeight: 700,
                py: '12px',
                px: '57px',
                borderRadius: '4px',
                background: themeColors.mainBlue,
                textTransform: 'none',
              }}
              onClick={() => {
                setSignInModal(true)
              }}
            >
              {t('button_browse_all')}
            </Button>
          </div>
        </div>
      </section>

      <section id="steps" className="steps">
        <div className="container">
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                sm: '40px',
                xs: '24px',
              },
              fontFamily: 'Helvetica',
              fontWeight: 700,
              textAlign: 'center',
              mb: {
                sm: '40px',
                xs: '30px',
              },
              color: themeColors.mainBlack,
            }}
          >
            {t('promo_section_title_how_it_works')}
          </Typography>
          <div className="steps-wrapper">
            <div className="steps-item">
              <div className="steps-item-image">
                <img src={carStep1} alt="" />
              </div>
              <div className="steps-item-title">{t('promo_how_it_works_step1_title')}</div>
              <div className="steps-item-desc">{t('promo_how_it_works_step1_desc')}</div>
            </div>
            <div className="steps-item">
              <div className="steps-item-image">
                <img src={carStep2} alt="" />
              </div>
              <div className="steps-item-title">{t('promo_how_it_works_step2_title')}</div>
              <div className="steps-item-desc">{t('promo_how_it_works_step2_desc')}</div>
            </div>
            <div className="steps-item">
              <div className="steps-item-image">
                <img src={carStep3} alt="" />
              </div>
              <div className="steps-item-title">{t('promo_how_it_works_step3_title')}</div>
              <div className="steps-item-desc">{t('promo_how_it_works_step3_desc')}</div>
            </div>
            <div className="steps-item">
              <div className="steps-item-image">
                <img src={carStep4} alt="" />
              </div>
              <div className="steps-item-title">{t('promo_how_it_works_step4_title')}</div>
              <div className="steps-item-desc">{t('promo_how_it_works_step4_desc')}</div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="about">
        <div className="container">
          <Grid container spacing={mobileOnly ? 0 : 3}>
            <Grid
              item
              sm={6}
              xs={12}
              sx={{
                position: 'relative',
                pr: {
                  xs: 0,
                  sm: '16px',
                  md: 0,
                },
              }}
            >
              <div className="about-image">
                <img src={aboutImage} alt="" />
              </div>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              sx={{
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  '&.xs-only': {
                    position: 'static',
                  },
                }}
              >
                <Box
                  className={[
                    smallDesktop && !mobileOnly ? 'md-only' : '',
                    mobileAndTabletOnly ? 'sm-only' : '',
                    mobileOnly ? 'xs-only' : '',
                  ].join(' ')}
                  sx={{
                    height: '331px',
                    overflowY: 'auto',
                    mb: '20px',
                    '&.md-only': {
                      height: '309px',
                      pr: '20px',
                    },
                    '&.sm-only': {
                      height: '231px',
                    },
                    '&.xs-only': {
                      height: 'auto',
                    },
                    scrollBehavior: 'smooth',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'thin',
                    pr: '30px',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '120px',
                      background: colorsConfig.buttonDisabled,
                      borderRadius: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      height: '70px',
                      background: colorsConfig.mainBlue,
                      borderRadius: '5px',
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: {
                        sm: '40px',
                        xs: '24px',
                      },
                      color: themeColors.mainBlack,
                      mb: {
                        xs: '20px',
                        sm: '32px',
                      },
                      mt: {
                        sm: '0',
                        xs: '24px',
                      },
                      textAlign: {
                        xs: 'center',
                        sm: 'left',
                      },
                    }}
                  >
                    {t('promo_section_title_about')}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '18.4px',
                      mb: '22px',
                    }}
                  >
                    {t('promo_section_about_p1')}
                    <br />
                    <br />
                    {t('promo_section_about_p2')}
                    <br />
                    <br />
                    {t('promo_section_about_p3')}
                    <br />
                    <br />
                    {t('promo_section_about_p4')}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  component={'a'}
                  href="#get-started"
                  sx={{
                    fontFamily: "'Helvetica', sans-serif",
                    fontWeight: 700,
                    py: '12px',
                    px: '57px',
                    borderRadius: '4px',
                    background: themeColors.mainBlue,
                    textTransform: 'none',
                    textAlign: 'center',
                    ml: {
                      xs: 'auto',
                      sm: 0,
                    },
                    mr: {
                      xs: 'auto',
                      sm: 0,
                    },
                    display: {
                      xs: 'block',
                      sm: 'inline-flex',
                    },
                  }}
                >
                  {t('button_work_with_us')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </section>
      <section id="faq" className="faq">
        <div className="container">
          <Typography
            variant="h3"
            sx={{
              fontFamily: '"Helvetica", sans-serif',
              fontWeight: 700,
              fontSize: '40px',
              color: themeColors.mainBlack,
              mb: '32px',
              mt: '15px',
              textAlign: 'center',
            }}
          >
            {t('promo_section_faq_title')}
          </Typography>
          <div className="accordion-wrapper">
            {faqItems.map((item, index) => {
              return <AccordionItem key={index} title={item.title} content={item.content} />
            })}
          </div>
        </div>
      </section>

      <section id="get-started" className="get-started">
        <div className="container">
          <Grid container spacing={3}>
            {mobileAndTabletOnly ? null : (
              <Grid item lg={6} xs={12} className="get-started-image">
                <img src={getStartedPicture} alt="" />
              </Grid>
            )}
            <Grid item lg={6} xs={12}>
              <div className="get-started-form">
                {getStartedSection === 1 ? (
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        color: themeColors.mainBlack,
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: {
                          xs: '24px',
                          sm: '40px',
                        },
                        fontWeight: 700,
                        mb: '10px',
                        ml: '10px',
                        textAlign: {
                          xs: 'center',
                          sm: 'left',
                        },
                      }}
                    >
                      {t('promo_section_title_sign_up')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: themeColors.mainBlack,
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: {
                          xs: '14px',
                          sm: '16px',
                        },
                        fontWeight: 400,
                        ml: '10px',
                        textAlign: {
                          xs: 'center',
                          sm: 'left',
                        },
                      }}
                    >
                      {t('promo_section_sign_up_p1')}
                    </Typography>
                    <SignUpCH setSignInModal={setSignInModal} />
                  </Box>
                ) : null}

                {getStartedSection === 2 ? (
                  <SignInPromo setGetStartedSection={setGetStartedSection} />
                ) : null}

                {getStartedSection === 3 ? (
                  <SignUpPromo setGetStartedSection={setGetStartedSection} />
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <div className="footer-main">
            <div className="footer-logo">
              <img style={{ width: '132px' }} src={Logo} alt="Fleatify" />
            </div>
          </div>
          <div className="footer-question">
            <Button
              sx={{
                minWidth: '364px',
                height: '48px',
                border: '1px solid',
                borderColor: colorsConfig.mainBlue,
                color: colorsConfig.mainBlue,
                textTransform: 'none',
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '16px',
                fontWeight: 700,
                borderRadius: 0,
                whiteSpace: 'nowrap',
                transition: '0.25s all ease-in-out',
                '&:hover': {
                  background: colorsConfig.mainBlue,
                  color: 'white',
                  transition: '0.25s all ease-in-out',
                },
              }}
              onClick={() => {
                setJokeModal(true)
              }}
            >
              {randomJokes[currentJokeIndex].question}
            </Button>
          </div>
          <Stack
            className={xsOnly ? 'xs-only' : ''}
            direction="row"
            gap="50px"
            justifyContent="center"
            sx={{
              mt: '40px',
              '&.xs-only': {
                display: 'none',
              },
            }}
          >
            <FooterCookiesAndPrivacyPolicy />
            <Stack gap="10px">
              <TextNormal
                component={'a'}
                href={`tel:${COMPANY_PHONE.number}`}
                sx={{
                  textDecoration: 'none',
                  transition: '0.25s all ease-in-out',
                  '&:hover': {
                    color: colorsConfig.mainBlue,
                    transition: '0.25s all ease-in-out',
                  },
                }}
              >
                {COMPANY_PHONE.text}
              </TextNormal>
              <TextNormal
                component={'a'}
                href={`mailto:${MAIN_EMAIL}`}
                sx={{
                  textDecoration: 'none',
                  transition: '0.25s all ease-in-out',
                  '&:hover': {
                    color: colorsConfig.mainBlue,
                    transition: '0.25s all ease-in-out',
                  },
                }}
              >
                {MAIN_EMAIL}
              </TextNormal>
            </Stack>
            <Stack gap="10px">
              <UITextLabelMedium>
                <NoTranslate>{COMPANY_NAME[REGION_LOCALE]}</NoTranslate>
              </UITextLabelMedium>
              <UITextBodySmall>{COMPANY_ADDRESS[REGION_LOCALE]}</UITextBodySmall>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '30px',
            }}
          >
            <TextNormal
              sx={{
                fontSize: '12px',
                color: colorsConfig.lightGray,
              }}
            >
              © 2025 Insercle AG.{' '}
              <span style={{ textTransform: 'uppercase' }}>{t('footer_all_rights_reserved')}</span>.
            </TextNormal>
          </Box>
        </div>
      </footer>
      <ModalWrap open={jokeModal} setOpen={setJokeModal}>
        <TextBoldNormal
          sx={{
            fontSize: '28px',
          }}
        >
          {randomJokes[currentJokeIndex].answer}
        </TextBoldNormal>
      </ModalWrap>
      <ModalWrap
        open={signInModal}
        setOpen={setSignInModal}
        className={xsOnly ? 'xs-only' : ''}
        wrapperStyles={{
          width: {
            xs: 'calc(100% - 30px)',
            sm: '600px',
          },
          px: {
            xs: '15px',
            md: 0,
          },
          background: 'white',
        }}
      >
        <Box
          sx={{
            mt: '10px',
            position: 'relative',
            display: 'flex',
          }}
        >
          <SignIn />
        </Box>
      </ModalWrap>
    </div>
  )
}

export default LandingPage
