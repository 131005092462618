import React, { useEffect, useState } from 'react'
import {
  getRviPolicy,
  rviStartRestitution,
} from '../../../store/features/rviPolicy/rviPolicy.actions'
import { getCompanyAddresses } from '../../../store/features/companyAddressesSlice/companyAddresses.actions'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, InputAdornment, Stack } from '@mui/material'
import UIFormInputSelect from '../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonAttention from '../../../ui/Buttons/ButtonAttention'
import { addWorkingDaysToCurrentDate } from '../../../utils/helpers/date.helpers'
import { useNavigate, useParams } from 'react-router-dom'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../config/themeConfig'
import { AssuredWorkloadRounded, CloseRounded } from '@mui/icons-material'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import FormUploadFileBase from '../../../components/Forms/FormComponents/FormUploadFile/FormUploadFileBase'
import UIUploadedFile from '../../../ui/UIUploadedFile/UIUploadedFile'
import FormUploadPhotos from '../../../components/Forms/FormComponents/FormUploadPhotos'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import UIBreadcrumbs from '../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { toast } from 'react-toastify'
import { vehicleExpertiseEnums } from '../../../utils/constants/enums.constants'
import dayjs from 'dayjs'
import { getPolicyCarTitle } from '../policies.helpers'
import { formErrors } from '../../../components/Forms/FormTemplates/formErrors'
import UIButtonLink from '../../../ui/UIButtons/UIButtonLink'
import AddLocationModal from '../../../components/Modals/AddLocationModal/AddLocationModal'

const expertiseOptions = [
  {
    label: 'form_input_select_on_spot_at_dealership',
    value: 'dealership',
  },
  {
    label: 'form_input_select_after_transporting_to_expert_center',
    value: 'expertCenter',
  },
]

export const getAddressType = (addressType) => {
  if (!addressType) return 'All'
  return addressType
}

export const getCompanyAddressesOptions = (companyAddresses, t) => {
  return companyAddresses.map((item) => {
    const address = item?.address ?? {}
    const addressLine = [
      address?.postcode,
      address?.addressLine1,
      address?.addressLine2,
      address?.city,
      t(address?.state),
      address?.country,
    ]
      .filter((e) => e && e !== '')
      .join(', ')
    return {
      value: addressLine,
      label: addressLine,
    }
  })
}

const RestituteVehicle = () => {
  const isLoading = useSelector((state) => state.rviPolicy.isLoading)
  const isSaving = useSelector((state) => state.rviPolicy.startRestitution.isLoading)
  const policy = useSelector((state) => state.rviPolicy.data)
  const companyAddresses = useSelector((state) => state.companyAddresses.data)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const carTitle = getPolicyCarTitle(policy)

  const [formData, setFormData] = useState({
    restitutionDate: null,
    location: '',
    mileage: '',
    vehicleExpertise: '',
    isAppraisalApproved: false,
    appraisalDocument: null,
    thirdPartyLink: '',
    salesPhotos: [],
    auxiliaryDocuments: [],
    vehicleRegistrationCard: null,
    comment: '',
  })
  const [showErrors, setShowErrors] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [addLocationModal, setAddLocationModal] = useState(false)

  const handleValidate = () => {
    const {
      mileage,
      restitutionDate,
      location,
      vehicleExpertise,
      isAppraisalApproved,
      salesPhotos,
      appraisalDocument,
      vehicleRegistrationCard,
    } = formData
    let isValid = true
    if (!mileage) isValid = false
    if (!restitutionDate) isValid = false
    if (!location) isValid = false
    if (!vehicleExpertise) isValid = false
    if (!vehicleRegistrationCard) isValid = false
    if (
      isAppraisalApproved &&
      vehicleExpertise === vehicleExpertiseEnums.dealership &&
      (!appraisalDocument || salesPhotos.length === 0)
    )
      isValid = false
    setFormValid(isValid)
    return isValid
  }

  const handleStartRestitution = async () => {
    const isValid = handleValidate()
    if (!isValid) {
      setShowErrors(true)
      return
    }
    const data = {
      ...formData,
      mileage: parseInt(formData.mileage),
    }
    if (formData.vehicleExpertise === vehicleExpertiseEnums.expertCenter) {
      data.isAppraisalApproved = false
    }
    const res = await dispatch(rviStartRestitution({ formData: data, policyId: policy?.id }))
    if (rviStartRestitution.fulfilled.match(res)) {
      toast.success(t('notification_restitution_request_submitted'))
      navigate('/rvi')
    }
  }

  useEffect(() => {
    if (showErrors) {
      handleValidate()
    }
  }, [showErrors, formData])

  useEffect(() => {
    if (policy) {
      dispatch(setCurrentPageTitle(t('modal_title_restitute_car', { carName: carTitle })))
    }
  }, [policy])

  useEffect(() => {
    dispatch(getRviPolicy({ policyId: params.id }))
    dispatch(getCompanyAddresses())
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack gap="30px">
        <Stack gap="30px" sx={{ position: 'relative' }}>
          {isLoading || isSaving ? <UIPreloader size={60} /> : null}
          <UIBreadcrumbs
            pages={[
              {
                icon: <AssuredWorkloadRounded />,
                name: 'title_rvi_policies',
                link: '/rvi',
              },
              {
                name: t('modal_title_restitute_car', { carName: carTitle }),
              },
            ]}
          />
          <Grid container spacing="24px">
            <Grid item xs={12} md={4}>
              <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t('form_input_label_restitute_date')}
                  inputFormat="DD/MM/YYYY"
                  mask={'__/__/____'}
                  disableMaskedInput={false}
                  minDate={addWorkingDaysToCurrentDate()}
                  shouldDisableDate={(date) => {
                    const day = dayjs(date).day()
                    return day === 0 || day === 6
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      value={formData.restitutionDate}
                      error={!formData.restitutionDate && showErrors}
                      helperText={
                        !formData.restitutionDate && showErrors ? formErrors.required : ''
                      }
                    />
                  )}
                  onChange={(newVal) => {
                    setFormData({
                      ...formData,
                      restitutionDate: newVal,
                    })
                  }}
                  value={formData?.restitutionDate}
                  views={['year', 'month', 'day']}
                  disablePast
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ position: 'relative' }}>
                <UIButtonLink
                  text="button_add_new_location"
                  sx={{
                    position: 'absolute',
                    top: '-18px',
                    right: 0,
                    fontSize: '12px',
                  }}
                  onClick={() => setAddLocationModal(true)}
                />
                <UIFormInputSelect
                  required
                  label="form_input_label_location_of_car"
                  options={getCompanyAddressesOptions(companyAddresses, t)}
                  value={formData.location}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      location: e.target.value,
                    })
                  }}
                  showHelperText={!formData.location && showErrors}
                  error={!formData.location && showErrors}
                  helperText={!formData.location && showErrors ? formErrors.required : ''}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                required
                label="form_input_label_mileage"
                value={sumDelimiter(formData.mileage)}
                inputProps={{
                  inputMode: 'numeric',
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mileage: e.target.value.replace(/[^0-9]/g, ''),
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>km</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
                error={!formData.mileage && showErrors}
                helperText={!formData.mileage && showErrors ? formErrors.required : ''}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UIFormInputSelect
                required
                label="form_input_label_vehicle_expertise"
                options={expertiseOptions}
                value={formData.vehicleExpertise}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    vehicleExpertise: e.target.value,
                  })
                }
                showHelperText={!formData.vehicleExpertise && showErrors}
                error={!formData.vehicleExpertise && showErrors}
                helperText={!formData.vehicleExpertise && showErrors ? formErrors.required : ''}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="form_input_label_third_party_link"
                value={formData.thirdPartyLink}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    thirdPartyLink: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} />
            {formData.vehicleExpertise === vehicleExpertiseEnums.dealership ? (
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" gap="7px">
                  <SimpleCheckbox
                    value={formData.isAppraisalApproved}
                    setValue={(value) => setFormData({ ...formData, isAppraisalApproved: value })}
                  />
                  <UITextRegular text="form_input_label_insercle_approved_appraisal" />
                </Stack>
              </Grid>
            ) : null}
            <Grid item xs={12} md={4}>
              <FormUploadFileBase
                label="form_input_label_vehicle_registration_card"
                handleSetFiles={(files) => {
                  setFormData({ ...formData, vehicleRegistrationCard: files[0] })
                }}
                error={showErrors && !formData.vehicleRegistrationCard}
                helperText={formErrors.required}
              />
            </Grid>
            <Grid item md={2}>
              {formData.vehicleRegistrationCard ? (
                <UIUploadedFile
                  handleRemove={() => setFormData({ ...formData, vehicleRegistrationCard: null })}
                />
              ) : null}
            </Grid>

            {formData.isAppraisalApproved &&
            formData.vehicleExpertise === vehicleExpertiseEnums.dealership ? (
              <>
                <Grid item xs={12} md={4}>
                  <FormUploadFileBase
                    label="form_input_label_appraisal_document"
                    handleSetFiles={(files) => {
                      setFormData({ ...formData, appraisalDocument: files[0] })
                    }}
                    error={
                      showErrors && !formData.appraisalDocument && formData.isAppraisalApproved
                    }
                    helperText={formErrors.required}
                  />
                </Grid>
                <Grid item md={2}>
                  {formData.appraisalDocument ? (
                    <UIUploadedFile
                      handleRemove={() => setFormData({ ...formData, appraisalDocument: null })}
                    />
                  ) : null}
                </Grid>
              </>
            ) : null}
            {formData.isAppraisalApproved &&
            formData.vehicleExpertise === vehicleExpertiseEnums.dealership ? (
              <>
                <Grid item xs={12}>
                  <FormUploadPhotos
                    label="form_input_label_sales_photos"
                    files={formData.salesPhotos}
                    handleSetFiles={(files) =>
                      setFormData({
                        ...formData,
                        salesPhotos: [...formData.salesPhotos, ...Array.from(files)],
                      })
                    }
                    error={
                      showErrors &&
                      formData.salesPhotos.length === 0 &&
                      formData.isAppraisalApproved
                    }
                    helperText={formErrors.required}
                  />
                </Grid>
                {formData.salesPhotos?.map((item, index) => {
                  return (
                    <Grid key={index} item xs={6} md={3}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '180px',
                          verticalAlign: 'top',
                          borderRadius: '6px',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          '& img': {
                            width: '100%',
                            verticalAlign: 'middle',
                            borderRadius: '6px',
                          },
                        }}
                      >
                        <img src={URL.createObjectURL(item)} alt="" />
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: '7px',
                            right: '7px',
                            width: '30px',
                            height: '30px',
                            background: colorsConfig.darkRed,
                            opacity: 0.5,
                            transition: '0.25s all ease-in-out',
                            '&:hover': {
                              opacity: 1,
                              background: colorsConfig.darkRed,
                              transition: '0.25s all ease-in-out',
                            },
                          }}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              salesPhotos: formData.salesPhotos.filter((item, i) => i !== index),
                            })
                          }
                        >
                          <CloseRounded sx={{ fill: colorsConfig.white }} />
                        </IconButton>
                      </Box>
                    </Grid>
                  )
                })}
              </>
            ) : null}
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
              <FormUploadFileBase
                label="form_input_label_additional_documentation"
                handleSetFiles={(files) => {
                  setFormData({
                    ...formData,
                    auxiliaryDocuments: [...formData.auxiliaryDocuments, ...Array.from(files)],
                  })
                }}
                error={showErrors && formData.auxiliaryDocuments?.length === 0}
                helperText={formErrors.required}
              />
            </Grid>
            <Grid item md={8}>
              <Stack direction="row" gap="10px" flexWrap="wrap">
                {formData.auxiliaryDocuments.map((item, index) => (
                  <UIUploadedFile
                    key={index}
                    handleRemove={() =>
                      setFormData({
                        ...formData,
                        auxiliaryDocuments: formData.auxiliaryDocuments.filter(
                          (e, i) => i !== index,
                        ),
                      })
                    }
                    sx={{
                      width: '80px',
                    }}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextFieldSmallEdible
                fullWidth
                multiline
                rows={4}
                label="form_input_label_comment"
                value={formData.comment}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    comment: e.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end" gap="8px">
          <ButtonHollow disabled={isLoading || isSaving} onClick={() => navigate(-1)}>
            {t('button_cancel')}
          </ButtonHollow>
          <ButtonAttention
            loadingText="preloader_saving_data"
            isLoading={isSaving}
            disabled={(!formValid && showErrors) || isLoading || isSaving}
            onClick={() => handleStartRestitution()}
          >
            {t('button_request_restitution')}
          </ButtonAttention>
        </Stack>
      </Stack>
      {addLocationModal && (
        <AddLocationModal
          open={addLocationModal}
          setOpen={setAddLocationModal}
          onSubmit={async (companyAddress) => {
            const { address } = companyAddress
            await dispatch(getCompanyAddresses())
            setFormData({
              ...formData,
              location: [
                address?.postcode,
                address?.addressLine1,
                address?.addressLine2,
                address?.city,
                t(address?.state),
                address?.country,
              ]
                .filter((e) => e && e !== '')
                .join(', '),
            })
          }}
          initialAddressType="/address-types/2"
        />
      )}
    </Box>
  )
}

export default RestituteVehicle
